<template>
  <v-card>
    <v-card-text>
      <v-row gutters="2" justify="end">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row gutters="2" justify="center">
        <v-col cols="12" md="12">
          <v-data-table :headers="headers" :items="addons" :search="search">
            <template v-slot:[`item.name`]="{ item }">
              <router-link
                :to="{
                  path: '/products/service/edit/' + item.productId,
                }"
              >
                {{ item.name }}
              </router-link>
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
              {{ item.updated_at | moment(campus.date_format) }}
            </template>
            <template v-slot:[`item.ACTIONS`]="{ item }">
              <v-icon @click="showDialogDelete(item)">delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="
              deleteRecord(dataAddon.productId);
              isShowDialogDelete = false;
            "
          >
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ForceDelete
      typeProduct="SERVICE"
      :validatePromotions="validatePromotions"
      :validatePackages="validatePackages"
      :productId="dataAddon.productId"
      :redirect="false"
      :isShow="isShowDialogForceDelete"
      @close-force-delete="cleanDataForceDelete()"
    ></ForceDelete>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ForceDelete from "@/view/pages/productAddon/components/ForceDelete";
export default {
  components: {
    ForceDelete,
  },
  data() {
    return {
      isShowDialogDelete: false,
      search: "",
      headers: [
        {
          text: this.$t("PRODUCTS_ADDONS.NAME"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("PRODUCTS_ADDONS.LAST_UPDATED"),
          align: "left",
          sortable: true,
          value: "updated_at",
        },
        {
          text: this.$t("PRODUCTS_ADDONS.ACTIONS"),
          align: "center",
          sortable: false,
          value: "ACTIONS",
        },
      ],
      dataAddon: {},
      validatePromotions: [],
      validatePackages: [],
    };
  },
  created() {
    this.getAddons();
  },
  methods: {
    ...mapActions(["getAddons", "deleteAddon"]),
    showDialogDelete(data) {
      this.dataAddon = data;
      this.isShowDialogDelete = true;
    },
    deleteRecord(productId) {
      this.deleteAddon(productId).then(data => {
        if (data.success) {
          this.validatePromotions = [];
          this.validatePackages = [];
          this.getAddons();
          this.$bvToast.toast(this.$t("MESSAGE.DELETE.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.DELETE.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        } else {
          this.validatePromotions = data.promotion ? data.promotion : [];
          this.validatePackages = data.package ? data.package : [];
          this.isShowDialogForceDelete =
            this.validatePromotions.length || this.validatePackages.length;
        }
      });
    },
    cleanDataForceDelete() {
      this.validatePromotions = [];
      this.validatePackages = [];
    },
  },
  computed: {
    ...mapGetters(["addons", "campus"]),
  },
};
</script>
