<template>
  <v-dialog
    v-model="isShowDialogForceDelete"
    max-width="500"
    eager
    :key="keyValidateDelete"
    persistent
  >
    <v-card>
      <v-card-title class="headline">
        <h3>{{ $t("PRODUCTS_ADDONS.NOT_DELETED") }}</h3>
      </v-card-title>

      <v-card-text>
        <h5
          class="pt-2"
          v-if="validatePromotions && validatePromotions.length > 0"
        >
          {{ $t("PRODUCTS_ADDONS.PROMOTION") }}
        </h5>
        <v-list-item
          v-for="(item, index) in validatePromotions"
          :key="`PRO-${index}`"
          class="mt-0 pt-0"
        >
          <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
            <v-list-item-title class="mt-0 mb-0 pt-0 pb-0" v-if="item.name"
              ><font-awesome-icon icon="times"></font-awesome-icon> &nbsp;{{
                item.name
              }}</v-list-item-title
            >
            <v-list-item-title class="mt-0 mb-0 pt-0 pb-0" v-else
              ><font-awesome-icon icon="times"></font-awesome-icon> &nbsp;No
              name</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <h5 v-if="validatePackages && validatePackages.length > 0" class="pt-5">
          {{ $t("PRODUCTS_ADDONS.PACKAGE") }}
        </h5>

        <v-list-item
          v-for="(item, index) in validatePackages"
          :key="`PAC-${index}`"
          class="mt-0 pt-0"
        >
          <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
            <v-list-item-title class="mt-0 mb-0 pt-0 pb-0" v-if="item.name"
              ><font-awesome-icon icon="times"></font-awesome-icon> &nbsp;{{
                item.name
              }}</v-list-item-title
            >
            <v-list-item-title class="mt-0 mb-0 pt-0 pb-0" v-else
              ><font-awesome-icon icon="times"></font-awesome-icon> &nbsp;No
              name</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <h5 v-if="validateCourses && validateCourses.length > 0" class="pt-5">
          {{ $t("PRODUCTS_ADDONS.COURSES") }}
        </h5>

        <v-list-item
          v-for="(item, index) in validateCourses"
          :key="`COU-${index}`"
          class="mt-0 pt-0"
        >
          <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
            <v-list-item-title
              class="mt-0 mb-0 pt-0 pb-0"
              v-if="item.product.name"
              ><font-awesome-icon icon="times"></font-awesome-icon> &nbsp;{{
                item.product.name
              }}</v-list-item-title
            >
            <v-list-item-title class="mt-0 mb-0 pt-0 pb-0" v-else
              ><font-awesome-icon icon="times"></font-awesome-icon> &nbsp;No
              name</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <h5 v-if="validateRooms && validateRooms.length > 0" class="pt-5">
          {{ $t("PRODUCTS_ADDONS.ROOMS") }}
        </h5>

        <v-list-item
          v-for="(item, index) in validateRooms"
          :key="`ROO-${index}`"
          class="mt-0 pt-0"
        >
          <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
            <v-list-item-title
              class="mt-0 mb-0 pt-0 pb-0"
              v-if="item.product.name"
              ><font-awesome-icon icon="times"></font-awesome-icon> &nbsp;{{
                item.product.name
              }}</v-list-item-title
            >
            <v-list-item-title class="mt-0 mb-0 pt-0 pb-0" v-else
              ><font-awesome-icon icon="times"></font-awesome-icon> &nbsp;No
              name</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <h5 v-if="validateServices && validateServices.length > 0" class="pt-5">
          {{ $t("PRODUCTS_ADDONS.ADDONS") }}
        </h5>

        <v-list-item
          v-for="(item, index) in validateServices"
          :key="`SER-${index}`"
          class="mt-0 pt-0"
        >
          <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
            <v-list-item-title
              class="mt-0 mb-0 pt-0 pb-0"
              v-if="item.product.name"
              ><font-awesome-icon icon="times"></font-awesome-icon> &nbsp;{{
                item.product.name
              }}</v-list-item-title
            >
            <v-list-item-title class="mt-0 mb-0 pt-0 pb-0" v-else
              ><font-awesome-icon icon="times"></font-awesome-icon> &nbsp;No
              name</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <div v-if="showForceDelete">
          {{ $t("PRODUCTS_ADDONS.FORCE_DELETE") }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn color="red darken-1" text @click="closeModal()">
          {{ $t("MESSAGE.DELETE.MODAL_NO") }}
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          @click="forceDelete()"
          v-if="showForceDelete"
        >
          {{ $t("MESSAGE.DELETE.MODAL_YES") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    typeProduct: {
      type: String,
      default: "",
    },
    validatePromotions: {
      type: Array,
      default: function() {
        return [];
      },
    },
    validatePackages: {
      type: Array,
      default: function() {
        return [];
      },
    },
    validateServices: {
      type: Array,
      default: function() {
        return [];
      },
    },
    validateCourses: {
      type: Array,
      default: function() {
        return [];
      },
    },
    validateRooms: {
      type: Array,
      default: function() {
        return [];
      },
    },
    productId: {
      type: String,
      default: "0",
    },
    redirect: {
      type: Boolean,
      default: true,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    showForceDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShowDialogForceDelete: false,
    };
  },
  computed: {
    productIdDelete() {
      if (this.productId === "0") {
        return this.$route.params.index;
      } else {
        return this.productId;
      }
    },
  },
  methods: {
    ...mapActions([
      "forceDeleteCourse",
      "forceDeleteRoom",
      "forceDeleteService",
    ]),
    forceDelete() {
      switch (this.typeProduct) {
        case "COURSE":
          this.forceDeleteCourse(this.productIdDelete).then(() => {
            if (this.redirect) {
              this.$router.push("/products/course");
            }
            this.isShowDialogForceDelete = false;

            this.$bvToast.toast(
              this.$t("MESSAGE.DELETE.DESCRIPTION_NEW").toString(),
              {
                title: this.$t("MESSAGE.DELETE.TITLE_NEW").toString(),
                variant: "success",
                solid: true,
              }
            );
          });
          break;
        case "ROOM":
          this.forceDeleteRoom(this.productIdDelete).then(() => {
            if (this.redirect) {
              this.$router.push("/products/room");
            }
            this.isShowDialogForceDelete = false;
            this.$bvToast.toast(
              this.$t("MESSAGE.DELETE.DESCRIPTION_NEW").toString(),
              {
                title: this.$t("MESSAGE.DELETE.TITLE_NEW").toString(),
                variant: "success",
                solid: true,
              }
            );
          });
          break;
        case "SERVICE":
          this.forceDeleteService(this.productIdDelete).then(() => {
            if (this.redirect) {
              this.$router.push("/products/service");
            }
            this.isShowDialogForceDelete = false;
            this.$bvToast.toast(
              this.$t("MESSAGE.DELETE.DESCRIPTION_NEW").toString(),
              {
                title: this.$t("MESSAGE.DELETE.TITLE_NEW").toString(),
                variant: "success",
                solid: true,
              }
            );
          });
          break;
        default:
          break;
      }
    },
    closeModal() {
      this.$emit("close-force-delete");
    },
    showModal() {
      const {
        validatePromotions,
        validatePackages,
        validateCourses,
        validateRooms,
      } = this;
      if (
        validatePromotions.length ||
        validatePackages.length ||
        validateCourses.length ||
        validateRooms.length
      ) {
        this.isShowDialogForceDelete = true;
      } else {
        this.isShowDialogForceDelete = false;
      }
    },
  },
  watch: {
    validatePromotions: {
      handler: function(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.showModal();
        }
      },
      deep: true,
    },
    validatePackages: {
      handler: function(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.showModal();
        }
      },
      deep: true,
    },
    validateCourses: {
      handler: function(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.showModal();
        }
      },
      deep: true,
    },
    validateRooms: {
      handler: function(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.showModal();
        }
      },
      deep: true,
    },
    validateServices: {
      handler: function(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.showModal();
        }
      },
      deep: true,
    },
    isShow: {
      handler: function(newValue) {
        if (newValue) {
          this.isShowDialogForceDelete = newValue;
        }
      },
    },
  },
};
</script>
