<template>
  <div class="floating-container">
    <!-- INFO OR RULE -->
    <span class="text-danger mr-3 mt-3" v-if="isInfo">
      {{ isInfoText }}
    </span>
    <!-- CANCEL -->
    <v-btn
      depressed
      color="grey"
      class="ml-3"
      :loading="cancel_loading"
      v-on:click="$emit('cancel')"
      v-if="btnCancel"
    >
      {{ textCancel }}
    </v-btn>

    <!-- SAVE -->
    <v-btn
      depressed
      color="primary"
      class="ml-3"
      :loading="save_loading"
      :disabled="save_disabled"
      v-on:click="$emit('save')"
      v-if="btnSave"
    >
      {{ textSave }}
    </v-btn>

    <!-- SAVE AND NEW -->
    <v-btn
      depressed
      color="primary"
      class="ml-3"
      :loading="save_new_loading"
      v-on:click="$emit('save-new')"
      v-if="btnSaveNew"
    >
      {{ textSaveNew }}
    </v-btn>

    <!-- DUPLICATED -->
    <v-btn
      depressed
      color="warning"
      class="ml-3"
      :loading="duplicate_loading"
      v-on:click="$emit('duplicate')"
      v-if="btnDuplicate"
    >
      {{ textDuplicate }}
    </v-btn>

    <!-- DELETE -->
    <v-btn
      depressed
      color="error"
      class="ml-3"
      :loading="delete_loading"
      v-on:click="$emit('delete')"
      v-if="btnDelete"
    >
      {{ textDelete }}
    </v-btn>
  </div>
</template>
<script>
export default {
  props: {
    // info
    isInfo: {
      type: Boolean,
      default: false,
    },
    isInfoText: String,

    // CANCEL
    btnCancel: {
      type: Boolean,
      default: true,
    },
    textCancel: String,
    cancel_loading: {
      type: Boolean,
      default: false,
    },

    // SAVE
    btnSave: {
      type: Boolean,
      default: true,
    },
    textSave: String,
    save_loading: {
      type: Boolean,
      default: false,
    },
    save_disabled: {
      type: Boolean,
      default: false,
    },

    // SAVE AND NEW
    btnSaveNew: {
      type: Boolean,
      default: false,
    },
    textSaveNew: String,
    save_new_loading: {
      type: Boolean,
      default: false,
    },

    // DUPLICATED
    btnDuplicate: {
      type: Boolean,
      default: false,
    },
    textDuplicate: String,
    duplicate_loading: {
      type: Boolean,
      default: false,
    },

    // DELETE
    btnDelete: {
      type: Boolean,
      default: false,
    },
    textDelete: String,
    delete_loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleScroll() {
      const container = document.querySelector(".floating-container");
      window.onscroll = function() {
        if (window.scrollY >= 50) {
          container.classList.add("floating-container--top-60");
        } else {
          container.classList.remove("floating-container--top-60");
        }
      };
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss">
.floating-container {
  background: #eef0f8;
  display: flex;
  justify-content: flex-end;
  top: 100px;
  transition: top 0.3s linear;
  right: 0;
  padding: 10px 0 5px 0;
  z-index: 3;
  @media (max-width: 992px) {
    top: 55px;
  }
}

.floating-container--top-60 {
  box-shadow: 0 0 10px 0 rgba(50, 50, 50, 0.49);
  top: 117px;
  padding-right: 25px;
  position: fixed;
  width: 100%;
  @media (max-width: 992px) {
    padding-right: 14px;
    top: 55px !important;
  }
}
</style>
