<template>
  <v-main>
    <v-row justify="end" class="mb-0 pb-0">
      <v-col cols="12">
        <Buttons
          :btnCancel="false"
          :textSave="$t('GENERAL.NEW')"
          v-on:save="addService"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <AddonTable></AddonTable>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
// Import internal scripts
// Import General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

// Import components
import AddonTable from "@/view/content/components/products_addons/addon/Table";
import { mapActions } from "vuex";
import Buttons from "@/view/content/components/Buttons";
export default {
  components: { AddonTable, Buttons },
  data() {
    return {
      tab: "",
    };
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PRODUCTS_ADDONS.TITLE") },
    ]);
    this.getAddons();
  },
  methods: {
    ...mapActions(["getAddons"]),
    addService() {
      this.$router.push({
        name: "product-service-new",
        params: { operation: "new" },
      });
    },
  },
};
</script>

<style scoped>
.btn-outline-primary {
  border-radius: 0;
}
ul {
  padding: 0px !important;
}
</style>
